<template>
  <div>
    <div :id="chartsName" style="width:4rem;height:3rem"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import bus from "@/utils/bus.js";
export default {
  props: {
    chartsFlag: {
      type: Number,
    },
    chartsName: {
      type: String,
    },
    total: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      flowbalanceTitle: this.$t("lang.Flowbalance"),
      storagebalanceTitle: this.$t("lang.Storagebalance"),
      UsedTitle: this.$t("lang.Used"),
      RemainingstoragespaceTitle: this.$t("lang.Remainingstoragespace"),
      ResidualflowTitle: this.$t("lang.Residualflow"),
    };
  },
  watch: {
    flowbalanceTitle() {
      this.initCharts();
    },
	total(){
		this.initCharts();
	},
	value(){
		this.initCharts();
	},
	chartsFlag(){
		this.initCharts();
	},
	chartsName(){
		this.initCharts();
	}
  },
  created() {
    bus.$on("langchange", () => {
      this.flowbalanceTitle = this.$t("lang.Flowbalance");
      this.storagebalanceTitle = this.$t("lang.Storagebalance");
      (this.UsedTitle = this.$t("lang.Used")),
        (this.RemainingstoragespaceTitle = this.$t(
          "lang.Remainingstoragespace"
        )),
        (this.ResidualflowTitle = this.$t("lang.Residualflow"));
    });
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts() {
      let myPieChart = echarts.init(document.getElementById(this.chartsName));
      let leftVal = this.value;
      let usedVal = (this.total - this.value).toFixed(2);
      let chartsColor = this.chartsFlag ? "#10c55b" : "#10c55b";
      let title = this.chartsFlag
        ? this.flowbalanceTitle
        : this.storagebalanceTitle;
      let unit = this.chartsFlag ? "MB" : "MB";
      let UsedTitle = this.UsedTitle;
      let leftTitle = this.chartsFlag
        ? this.ResidualflowTitle
        : this.RemainingstoragespaceTitle;
      myPieChart.setOption({
        title: {
          text: title,
          textStyle: {
            fontSize: this.setfontSize(0.14),
            color: "#B2B2B2",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function(name) {
			  return name.name + ':' +name.value+unit
			},
        },
        legend: {
          orient: "vertical",
          top: "bottom",
          textStyle: {
            color: "#B2B2B2",
          },
          formatter: function (name) {
            let data = [
              { value: usedVal, name: UsedTitle },
              { value: leftVal, name: leftTitle },
            ];
            let target;
            for (let i = 0, l = data.length; i < l; i++) {
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            return name + " " + target + unit;
          },
        },
        series: [
          {
            // name: '访问来源',
            type: "pie",
            radius: "55%",
            center: ["45%", "40%"],
            data: [
              {
                value: usedVal,
                name: UsedTitle,
                itemStyle: { color: "#E75A48" },
              },
              {
                value: leftVal,
                name: leftTitle,
                itemStyle: { color: chartsColor },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
          },
        ],
      });
       window.addEventListener('resize',function(){
        myPieChart.resize()
      })
    },
    setfontSize(res) {
      let docEl = document.documentElement,
        clientWidth =  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <el-container style="width:100%;height:100%">
    <div class="mask" v-if="showModal" @click="handleHide"></div>
    <el-header height="103">
      <img class="erweima" v-if="langch" src="../assets/imgs/logo1.jpeg" />
			<img class="erweima" v-else src="../assets/imgs/logo.png" />
      <div class="headerLeft">
				<div class="headerxx" v-if="monys!=0">
					<img src="@/assets/imgs/devlis/xiaoxitishi.png" />
					<div v-if="monys==1">{{$t('lang.balanceisinsufficient')}}</div>
					<div v-if="monys==2">{{$t('lang.accountarrears')}}</div>
					<div v-if="monys==3">{{$t('lang.accountarrearsa')}}</div>
					<div style="color: #D5AB4F;margin:0 0.1rem ;cursor: pointer;" @click="chonzhi">{{$t('lang.Clickrecharge')}}</div>
				</div>
        <p style="color:#fff;margin-right:10px;font-size:.18rem">{{$t("lang.LanguageTransform")}}</p>
        <img @click="changeimg" v-if="langch" class="english" :src="require('@/assets/imgs/zhong.png')"/>
				<img @click="changeimg" v-else class="english" :src="require('@/assets/imgs/En.png')"/>
        <div class="showfunc" @click="showfunc">
          <img class="userimg" :src="headerImg" alt=" " />
          <p>{{ username }}</p>
          <img class="updownimg" src="../assets/imgs/xaila.png" />
        </div>
      </div>
      <!-- 设置功能 -->
      <div class="setfunc" :style="{ display: setfunc }" v-if="!stauts">
        <p @click="revisepas">{{$t("lang.ChangePassword")}}</p>
        <p v-if="stauts" @click="setbalance">{{$t("lang.Quotabalance")}}</p>
        <p v-if="stauts" @click="recommendPlan">{{$t("lang.Streamingscheme")}}</p>
        <p @click="setUser">{{$t("lang.setup")}}</p>
        <p @click="signOut">{{$t("lang.signout")}}</p>
      </div>
			<div class="setfuncs" :style="{ display: setfunc }" v-else>
				<div class="setfuncs_a" @click="core(4)">
					<div class="usernames">
						<img :src="headerImg" />
						<div>
							<div>
								<span style="font-size: .16rem;">{{username}}</span>
							</div>
							<div style="margin-top: 5px;">
								<span>{{$t("lang.IDs")}}：</span>
								<span>{{userdet.email}}</span>
							</div>
						</div>
					</div>
					<div class="userpass" @click.stop="revisepas">{{$t("lang.ChangePassword")}}</div>
				</div>
				<div class="setfuncs_a" @click="core(6)" style="font-size: .16rem;">
					<div>
						<div>{{$t('lang.accountoverview')}}</div>
						<div style="margin-top: 5px;">
							<span>{{$t('lang.balances')}}：￥</span>
							<span>{{userbalance}}</span>
						</div>
					</div>
					<div class="userpass">{{$t('lang.Balancerecharge')}}</div>
				</div>
				<div class="setfuncs_b">
					<div @click="core(3)">
						<img src="@/assets/imgs/devlis/tlfa.png" />
						<span>{{$t("lang.Streamingschemes")}}</span>
					</div>
					<div @click="core(1)">
						<img src="@/assets/imgs/devlis/dindgan.png" />
						<span>{{$t('lang.Ordercenter')}}</span>
					</div>
					<div @click="core(2)">
						<img src="@/assets/imgs/devlis/zahngdan.png" />
						<span>{{$t('lang.billingcenter')}}</span>
					</div>
				</div>
				<div class="setfuncs_c" @click="core(4)">
					<span>{{$t("lang.accountsettings")}}</span>
				</div>
				<div class="setfuncs_c" style="border: none;" @click="signOut">
					<span>{{$t("lang.signout")}}</span>
				</div>
			</div>

      <!-- 修改密码 -->
      <el-dialog
        :visible.sync="showpas"
        width="30%"
        top="1rem"
        :title="$t('lang.ChangePassword')"
        :show-close="false"
        custom-class="setpas"
        center
      >
        <el-form :model="formVal" :rules="rules" ref="ruleForm">
          <el-form-item :label="$t('lang.accountnumber')+'：'">
            <p>{{ emailval }}</p>
          </el-form-item>
          <el-form-item :label="$t('lang.Originalpassword')" prop="oldpasval" class="inputd">
            <el-input :type="pwdInputType" maxlength="16" v-model="formVal.oldpasval" style="width: 3rem;"></el-input>
						<img class="eyes-icon" :src="eyeIcon" @click="handleShowPwd(1)" style="cursor: pointer"/>
          </el-form-item>
          <el-form-item :label="$t('lang.Newpassword')" prop="newpasval" class="inputd">
            <el-input :type="pwdInputTypes" maxlength="16" v-model="formVal.newpasval" style="width: 3rem;"></el-input>
						<img class="eyes-icon" :src="eyeIcons" @click="handleShowPwd(2)" style="cursor: pointer"/>
          </el-form-item>
          <el-form-item :label="$t('lang.Confirmpassword')" prop="conpasval" class="inputd">
            <el-input :type="pwdInputTypesa" maxlength="16" v-model="formVal.conpasval" style="width: 3rem;"></el-input>
						<img class="eyes-icon" :src="eyeIconsa" @click="handleShowPwd(3)" style="cursor: pointer"/>
          </el-form-item>
        </el-form>
        <div class="submitpas">
          <button @click="showpas = false" style="margin-left: 1.4rem;">
            <p>{{ $t('lang.cancel') }}</p>
          </button>
          <button @click="submitpas('ruleForm')">
            <p>{{ $t('lang.submit') }}</p>
          </button>
        </div>
      </el-dialog>
      <!-- 退出登录 -->
      <el-dialog
        :visible.sync="backlogin"
        width="5rem"
        :title="$t('lang.Logout')"
        :show-close="false"
        custom-class="backlogin"
      >
        <p>{{$t('lang.Doyouwanttologout')}}</p>
        <div class="submitpas">
          <button @click="backlogin = false">
            <p>{{$t('lang.cancel')}}</p>
          </button>
          <button @click="backLogin">
            <p>{{$t('lang.determine')}}</p>
          </button>
        </div>
      </el-dialog>
    </el-header>
    <el-container>
      <router-view></router-view>
    </el-container>
  </el-container>
</template>

<script>
import * as nav from "@/api/apis";
import { getTime } from "@/utils/time.js";
import JSEncrypt from "jsencrypt";
import { imgUrl } from "../utils/request";
import bus from "@/utils/bus.js";
import pieCharts from "../components/echarts/pie";
export default {
  components: {
    pieCharts,
  },
  
  data() {
		
    var newpasval = (rule, value, callback) => {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (!value) {
        callback(new Error( this.$t("lang.Pleaseenteranewpassword")));
      } else if (!reg.test(value)) {
        callback(new Error(this.$t('lang.defPassword')));
      }else{
        callback()
      }
    };
    var conpasval = (rule, value, callback) => {
      // var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (value !== this.formVal.newpasval) {
        callback(new Error( this.$t("lang.PasswordNotMatch")));
      } else {
        callback();
      }
    };
		var newpasvals = (rule, value, callback) => {
		  if (!value||value=='' ) {
		    callback(new Error( this.$t("lang.Pleaseentertheoriginalpassword")));
		  } else {
		    callback();
		  }
		};
    return {
      headerImg: require("../assets/imgs/yhzcl.png"),
      username: "",
      oldpasval: "",
      newpasval: "",
      conpasval: "",
      setfunc: "none",
      showbalance: false /* 配置余额 */,
      showpas: false /* 修改密码 */,
      usersInterface: true /* 用户界面 */,
      adminInterface: false /*  */,
      tableRouter: true /*  */,
      backlogin: false,
      langchange: "zhong",
			langch:true,
      rules: {
        oldpasval: [
          {
            required: true,
            // validator: oldpasval,
            //message:this.$t("lang.Pleaseentertheoriginalpassword"),
						validator: newpasvals,
            trigger: "blur",
          },
        ],
        newpasval: [
          {
            required: true,
            validator: newpasval,
            trigger: "blur",
          },
        ],
        conpasval: [
          {
            required: true,
            // message: this.$t("lang.Pleaseentertheconfirmationpassword"),
            validator: conpasval,
            trigger: "blur",
          },
        ],
      },
      passwordNotMatch: this.$t("lang.PasswordNotMatch"),
      emailval: "",
      formVal: {
        oldpasval: "",
        newpasval: "",
        conpasval: "",
      },
      balance: [],
      stauts: true,
      showModal: false,
			userdet:'',
			eyeIcon:require("@/assets/imgs/close_eyes.png"),
			eyeIcons:require("@/assets/imgs/close_eyes.png"),
			eyeIconsa:require("@/assets/imgs/close_eyes.png"),
			pwdInputType:'password',
			pwdInputTypes:'password',
			pwdInputTypesa:'password',
			userbalance:'',
			monys:'0',
			usertime:null
    };
  },
  watch: {
    $route: {
      handler: function () {
				//console.log('dons')
				this.userdet=JSON.parse(sessionStorage.getItem("user"))
				nav.userId({ email: this.userdet.email }).then((response) => {
				  sessionStorage.setItem("user", JSON.stringify(response.data));
				  this.username = response.data.name;
					this.headerImg = response.data.img;
				});
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
		this.userdet=JSON.parse(sessionStorage.getItem("user")) ||{}
		if(this.userdet.type==1){
			this.usertime=setInterval(()=>{
				this.getuser()
			},2000)
		}
    if (this.userdet.img) {
      this.headerImg = this.userdet.img;
    }
    if (this.userdet.email) {
      this.emailval = this.userdet.email;
    } else {
      this.$router.push("/");
    }
    if (this.userdet.type == 1) {
      // let userid = JSON.parse(sessionStorage.getItem("user")).id;
      // nav.deviceQuota({ id: userid }).then((res) => {
      //   if (res.data) {
      //     this.balance = res.data.filter(item => item.type === 2);
      //   }
      // });
    } else {
      this.stauts = false;
    }
    if (this.userdet.name) {
      this.username = this.userdet.name;
    } else {
      this.username = this.userdet.email;
    }
		//console.log(sessionStorage.getItem('language'))
		let sesw=setInterval(()=>{
			if(sessionStorage.getItem('language')){
				clearInterval(sesw)
				if (sessionStorage.getItem('language') == "En") {
					this.$i18n.locale = "en";
					this.langch=true
					this.langchange = "En";
				} else {
					this.$i18n.locale = "cn";
					this.langch=false
					this.langchange = "zhong";
				}
			}
		},100)
  },
	
  methods: {
		//获取用户信息
		async getuser(){
			if(JSON.parse(sessionStorage.getItem("user"))){
				let email=JSON.parse(sessionStorage.getItem("user")).email
				let res=await nav.userId({email:email})
				//console.log(res)
				if(res){
					let data=res.data
					let moneysa=data.money
					let monysss=data.balance?data.balance:'0'
					data.password=''
					sessionStorage.setItem("user", JSON.stringify(data));
					sessionStorage.setItem("balance", monysss);
					sessionStorage.setItem("money", moneysa);
					let mony=Number(sessionStorage.getItem("money"))
					mony=mony?mony:0
					let balancea=Number(sessionStorage.getItem("balance"))
					// if(balancea==0){
					// 	this.monys=1
					// }
					if(balancea<0){
						if(balancea>-10){
							this.monys=2
						}else{
							this.monys=3
						}
					}else{
						if(balancea<mony){
							this.monys=1
						}else{
							this.monys=0
						}
					}
				}
			}else{
				if(this.usertime){
					clearInterval(this.usertime)
				}
			}
		},
    showfunc() {
      this.showModal = true;
			this.userbalance = sessionStorage.getItem("balance")
      if (this.setfunc == "none") {
        this.setfunc = "block";
      } else {
        this.setfunc = "none";
        this.showpas = false;
        this.showbalance = false;
      }
    },
    handleHide() {
      this.setfunc = "none";
      this.showbalance = false;
      this.showModal = false;
    },
    revisepas() {
      /* 修改密码 */
      this.setfunc = "none";
      this.showbalance = false;
      this.showModal = false;
      if (this.showpas == false) {
        this.showbalance = false;
        this.showpas = true;
				this.eyeIcon=require("@/assets/imgs/close_eyes.png");
				this.eyeIcons=require("@/assets/imgs/close_eyes.png");
				this.eyeIconsa=require("@/assets/imgs/close_eyes.png");
				this.pwdInputType='password';
				this.pwdInputTypes='password';
				this.pwdInputTypesa='password';
      } else {
        this.showpas = false;
      }
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
        for (let key in this.formVal) {
          this.formVal[key] = "";
        }
      });
    },
    setbalance() {
      this.showModal = false;
      this.showfunc();
      this.$router.push("/nav/quotaBalance");
      /* 配置余额 */
      // if (!this.showbalance) {
      //   this.showpas = false;
      //   this.showbalance = true;
      // } else {
      //   this.showbalance = false;
      // }
    },
		//充值
		chonzhi(){
			this.$router.push({
				path:'/nav/personal',
				query:{ind:5}
			})
		},
    //切换中英文
    changeimg() {
      if (this.langchange == "En") {
        this.langchange = "zhong";
        this.$i18n.locale = "cn-CN";
				this.langch=false
      } else {
        this.langchange = "En";
        this.$i18n.locale = "en-US";
				this.langch=true
      }
      sessionStorage.setItem("language", this.langchange);
      // this.rules.oldpasval[0].message = this.$t( "lang.Pleaseentertheoriginalpassword");
      // this.rules.newpasval[0].message = this.$t("lang.Pleaseenteranewpassword");
      // this.rules.conpasval[0].message = this.$t( "lang.Pleaseentertheconfirmationpassword");
      // this.passwordNotMatch = this.$t("lang.PasswordNotMatch");
      bus.$emit("langchange", this.langchange);
    },
    submitpas(ruleForm) {
      //this.showfunc()
      /* 修改密码 */
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          if (this.formVal.newpasval !== this.formVal.conpasval) {
            this.$message.error(this.passwordNotMatch);
            return false;
          }
          const jse = new JSEncrypt();
          const publiceKey =
            "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXYI847fATDgZY6gFvGzaR7UXN9c007DEb6M6DvbSE5F3+apLG+PqujLIa8YzvSGntT3YTpN/RNQxo4I7NWIIbHC5u4zobDW1XNJOun5NUgnLglJxI5LrFIo4VzRA8x3TPh4Eb2fuc4eSO3j5XzL2OY8h+H1Npz4JwOdj7Okoc4QIDAQAB";
          jse.setPublicKey(publiceKey);
          let params = {
            email: this.userdet.email,
            password: jse.encrypt(this.formVal.oldpasval),
            newpassword: jse.encrypt(this.formVal.newpasval),
            passwordqr: jse.encrypt(this.formVal.conpasval),
          };
          nav.Upload(params).then((res) => {
						this.$message.success(this.$t('lang.again'));
						this.showpas = false;
						setTimeout(()=>{
							sessionStorage.clear();
							this.$router.push("/userlogin");
						},2000)
          });
        } else {
          return false;
        }
      });
    },
    recommendPlan() {
      this.showModal = false;
      this.showfunc();
      this.tableRouter = false;
      this.adminInterface = false;
      this.usersInterface = false;
      this.$router.push("/nav/pushscheme");
    },
    setUser() {
      this.showfunc();
      this.tableRouter = false;
      this.usersInterface = false;
      this.adminInterface = false;
      this.$router.push("/nav/users");
			this.showModal = false;
    },
    signOut() {
      this.setfunc = "none";
      this.showbalance = false;
      this.showModal = false;
      /* 退出登录 */
      this.backlogin = true;
    },
    backLogin() {
      /* 退出登录 */
      this.backlogin = false;
      let times = new Date();
      let time = getTime(times);
			setTimeout(()=>{
				sessionStorage.clear();
				this.$router.push("/userlogin");
			},1000)
      let datas = { loginOutTime: time.time, email: this.userdet.email };
      nav.loginOut(datas).then((res) => {
        this.$message.success(this.$t('lang.Exitsuccessful'));
      });
    },
		//跳转到个人中心
		core(index){
			this.setfunc = "none";
			this.showbalance = false;
			this.showModal = false;
			if(index!==6){
				this.$router.push({
					path:'/nav/personal',
					query:{ind:index}
				})
			}else{
				this.$router.push({
					path:'/nav/personal',
					query:{ind:5}
				})
			}
		},
		//显示隐藏密码
		handleShowPwd(ind) {
		  if(ind==1){
				this.pwdInputType = this.pwdInputType === "password" ? "text" : "password";
				this.eyeIcon =
				  this.eyeIcon === require("@/assets/imgs/close_eyes.png")
				    ? require("@/assets/imgs/kejian.png")
				    : require("@/assets/imgs/close_eyes.png");
			}else if(ind==2){
				this.pwdInputTypes = this.pwdInputTypes === "password" ? "text" : "password";
				this.eyeIcons =
				  this.eyeIcons === require("@/assets/imgs/close_eyes.png")
				    ? require("@/assets/imgs/kejian.png")
				    : require("@/assets/imgs/close_eyes.png");
			}else{
				this.pwdInputTypesa = this.pwdInputTypesa === "password" ? "text" : "password";
				this.eyeIconsa =
				  this.eyeIconsa === require("@/assets/imgs/close_eyes.png")
				    ? require("@/assets/imgs/kejian.png")
				    : require("@/assets/imgs/close_eyes.png");
			}
		},
  },
};
</script>

<style lang="less" scoped>
.el-header {
  height: 1.03rem;
  display: flex;
  padding-left: .21rem;
  padding-right: .37rem;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(0deg, #0b1114, #0b0f12);
  box-shadow: 0px 5px 10px 0px #e2e2e2;
  .erweima{
    width: 4.21rem;
    height: .34rem;
  }
  .headerLeft {
    display: flex;
    align-items: center;
		.headerxx{
			font-size: .14rem;
			color: #B2B2B2;
			display: flex;
			align-items: center;
			img{
				width: .20rem;
				height: .20rem;
				margin-right: .09rem;
			}
		}
    img.english {
      width: .3rem;
      height: .3rem;
      margin-right: .39rem;
    }
    .showfunc {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      img.userimg {
        width: .54rem;
        height: .54rem;
        border-radius: 50%;
        margin-right: .14rem;
      }
      p {
        font-size: .16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        max-width: 1.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .updownimg {
        width: .08rem;
        height: .05rem;
        margin-top: .06rem;
        margin-left: .07rem;
      }
    }
  }
}
.el-container {
  width: 100%;
  height: calc(100% - 1.03rem);
}
/*设置功能 */
.setfunc {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: .85rem;
  right: .18rem;
  min-width: 1.3rem;
  z-index: 1000;
  background: #0b1114;
  border-radius: 6px;
  padding: .14rem 0px .12rem .12rem;
  cursor: pointer;
  > p {
    font-size: .14rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #b7bac5;
    line-height: .3rem;
  }
}
.setfuncs{
	display: flex;
	flex-direction: column;
	position: absolute;
	top: .85rem;
	right: .5rem;
	width: 3.6rem;
	z-index: 9999;
	background: #0B1114;
	box-shadow: 0 0 5px #E0E0E0;
	border-radius: 3px;
	color: #B7B7B7;
	font-size: .14rem;
	.setfuncs_a{
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #2D2F32;
		padding:.1rem .1rem .1rem .18rem;
		box-sizing: border-box;
		cursor: pointer;
		font-size: .14rem;
		.usernames{
			display: flex;
			align-items: center;
			img{
				width: .6rem;
				height: .6rem;
				border-radius: 50%;
				margin-right: .13rem;
				display: inline-block;
			}
			div{
				div{
					width: 1.6rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
		.userpass{
			border: 1px solid #307B37;
			height: .3rem;
			line-height: .3rem;
			white-space: nowrap;
			padding: 0 .1rem;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: center;
			border-radius: 4px;
			color: #307B37;
			font-size: .14rem;
		}
	}
	.setfuncs_b{
		display: flex;
		flex-flow: row;
		justify-content: space-around;
		align-items: center;
		border-bottom: 1px solid #2D2F32;
		padding:.2rem 0;
		box-sizing: border-box;
		font-size: .16rem;
		div{
			display: flex;
			flex-flow: column;
			align-items: center;
			cursor: pointer;
			img{
				height: .35rem;
				margin-bottom: .1rem;
			}
		}
	}
	.setfuncs_c{
		border-bottom: 1px solid #2D2F32;
		padding:.1rem .1rem .1rem .18rem;
		box-sizing: border-box;
		font-size: .16rem;
		cursor: pointer;
	}
}
/* 配置余额 */
.setbalance {
  width: 3.89rem;
  height: 3rem;
  overflow: auto;
  position: absolute;
  right: 1.6rem;
  top: .88rem;
  box-shadow: 0px 0px 1px 1px #525252;
  background: #0b1114;
  border-radius: 6px;
  z-index: 10;
  padding: .15rem .15rem .15rem .26rem;
  > p {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #b2b2b2;
    line-height: .3rem;
  }
  .surface {
    display: flex;
    margin-top: .2rem;
    justify-content: space-between;
    .showspace {
      display: flex;
      margin-bottom: 9px;
      .square {
        width: .12rem;
        height: .12rem;
        background: #78d1bf;
        border-radius: 2px;
        margin-right: .08rem;
      }
    }
    .circular {
      width: .83rem;
      height: .83rem;
      margin-top: .18rem;
      margin-bottom: .25rem;
      background: #78d1bf;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #b2b2b2;
    }
    .flow {
      width: 1.94rem;
    }
    .storage {
      width: 1.94rem;
    }
  }
}
.mask {
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
/deep/.inputd{
	position: relative;
	.el-input__inner{
		padding: 0 .4rem 0 .15rem;
	}
	.eyes-icon {
	  width: 0.22rem;
	  height: 0.14rem;
	  position: absolute;
	  top: 0.15rem;
	  right: 0.15rem !important;
	}
}
/deep/.setpas{
	 .el-form-item{
		margin-bottom: .4rem;
	}
}
</style>
